import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TelegrAPIService {

  constructor(
    private http: HttpClient
  ) { }

  executeTelegramInfoBot(): Observable<any> {
    return this.http.get('https://my.callofduty.com/api/papi-client/stats/cod/v1/title/mw/platform/psn/gamer/FaceSmasher666/profile/type/mp');
  }
}
