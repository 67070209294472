import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { TelegrAPIService } from 'src/app/Services/telegr-api.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  @ViewChild('usernameInput', { static: false }) usernameInput: ElementRef;
  @ViewChild('passwordInput', { static: false }) passwordInput: ElementRef;
  showPassword = false;
  showLoggingIn = false;
  showLoader = false;

  username = '';
  password = '';

  loggingInText = 'Logging in';
  gugus = '';

  @HostListener('document:keydown', ['$event'])
  keypress(e: KeyboardEvent) {
      if (e.key === 'Enter') {
        if (!this.showPassword && this.username) {
          this.showPassword = true;
          setTimeout(() => {
            this.passwordInput.nativeElement.focus();
          }, 1);
        }

        if (this.password && this.showPassword) {
          this.showLoggingIn = true;
          this.passwordInput.nativeElement.blur();
          this.showLoader = true;

          if (this.password === 'sendinfo') {
            this.teleApi.executeTelegramInfoBot().subscribe(resp => {
              console.log(resp);
            });
          }
          for(let i = 0; i < 15; i++) {
            setTimeout(() => {
              this.loggingInText += '.';
              if (i === 14) {
                this.gugus += 'No login function found... please contact the programmer of this gugus code!';
              }
            }, 1000 * (i + 1));
          }
        }
      }
  }

  constructor(
    private teleApi: TelegrAPIService
  ) { }

  ngOnInit() {
  }

  test() {
    this.passwordInput.nativeElement.focus();
  }

}
