import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainmenu',
  templateUrl: './mainmenu.component.html',
  styleUrls: ['./mainmenu.component.scss']
})
export class MainmenuComponent implements OnInit {
  selectedMenuClass = 'Main';
  classForCube = 'Main';

  menuSlots = ['Main', 'WoW', 'COD', 'Diablo', 'Paintball', 'JOP'];

  constructor() { }

  ngOnInit() {
  }

  addClassToCube() {
    this.classForCube = this.selectedMenuClass;
  }

  removeClassFromCube() {
    this.classForCube = '';
  }

  changeCubeFace(faceName: string) {
    this.classForCube = faceName;
  }

}
