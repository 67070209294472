import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { MainmenuComponent } from './components/mainmenu/mainmenu.component';
import { MainPageComponent } from './components/main-page/main-page.component';


const routes: Routes = [
  {path: '', component: LoginPageComponent},
  {path: 'mainmenu', component: MainPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
